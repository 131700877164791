<template>
<v-form ref="form" lazy-validation>
    <v-dialog v-model="dialog" max-width="900px" persistent transition="dialog-top-transition" @keydown.esc="close">
        <v-card>
            <v-card-title>
                Repair Form:{{ editedItem.wellname }}

            </v-card-title>
            <v-card-text>
                <v-row dense no-gutters style="margin-top: 4px; ">
                    <v-col cols="12" sm="5" md="5">
                        <v-select :items="user_list" v-model="editedItem.operator_id" item-value="id" :readonly="status == 6 || status == 7" item-text="name" label="Repair by" :rules="[(v) => !!v || 'Repaired by required']" dense @change="changes++">
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" style="margin-left:12px">
                        <datepicker label="Repair Date" :edit="true" v-model="editedItem.date_reparation" :rules="[(v) => !!v || 'Repair Date required']" @change="changes++" :key="cs"></datepicker>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <h4>Comment:</h4>
                        <v-textarea align-self="center" hide-spin-buttons autocomplete="off" v-model="editedItem.comment" :readonly="status == 6 || status == 7" :rules="[(v) => !!v || 'Comment required']" @input="changes++" outlined dense rows="2">
                        </v-textarea>
                    </v-col>
                </v-row>
            
                    <v-row dense no-gutters>
                        <v-col dense cols="12" sm="5" md="5"><b>Element</b></v-col>
                        <v-col dense cols="12" sm="2" md="2" align="left"><b>Repared</b></v-col>
                    </v-row>
                    <v-row dense class="rowh2" no-gutters align="center" v-for="item in repair_list" :key="item.id">
                        <v-col dense cols="12" sm="5" md="5">
                            <span>
                                {{ item.component }} &nbsp;&nbsp; {{ item.issue }}
                            </span>
                        </v-col>
                        <v-col dense cols="12" sm="2" md="2">
                            <v-checkbox style="margin-bottom:8px" v-model="item.repared" @change="changes++">
                            </v-checkbox>
                        </v-col>

                    </v-row><br>
           
                <div>
                    <uploader :key="kfile" :files_list="uploadedFiles" :uploading_file="uploading" @change_files="change_files" :edit_file="status!=7" />

                </div>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" color="blue darken-1" @click.stop="accept_daialog(true)" v-if="status == 6">
                    Accept
                </v-btn>
                <v-btn class="ma-1" color="blue darken-1" @click.stop="accept_daialog(false)" v-if="status == 6">
                    Return
                </v-btn>

                <v-btn class="ma-1" color="blue darken-1" @click.stop="save(6)" v-if="status <= 5 || status == 8" :disabled="changes < 1 && complete != 1">
                    Completed
                </v-btn>
                <v-btn class="ma-1" color="blue darken-1" @click.stop="save(status)" v-if="status != 7 && status != 6" :disabled="changes < 1">
                    Save
                </v-btn>
                <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-form ref="form" lazy-validation>
        <v-dialog v-model="dialog_accept" max-width="600px" persistent transition="dialog-top-transition">
            <v-card>
                <v-card-text>
                    <br />

                    {{ accept ? "Conclusion" : "Justification" }}
                    <v-textarea ref="comment" outlined dense v-model="accept_comment" :rules="[
                (v) =>
                  !!v ||
                  (accept ? 'Conclusion' : 'Justification') + ' required',
              ]" variant="outlined" auto-grow></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="accept" class="ma-1" color="blue darken-1" @click.stop="save(7)">
                        Accept
                    </v-btn>

                    <v-btn v-if="!accept" class="ma-1" color="blue darken-1" @click.stop="save(8)">
                        Return
                    </v-btn>
                    <v-btn color="blue darken-1" @click.stop="accept_dialog_close()" class="ma-1">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
    <confirmdialog ref="validation" />
</v-form>
</template>

<script>
import SaveReparation from "../graphql/Reparation/SaveReparation.gql";
import getFiles_list from "../graphql/Reparation/DOCS.gql"; 
export default {
    components: {
        //filelist: () => import("./FileList.vue"),
        datepicker: () => import("../components/DatePicker.vue"),
        confirmdialog: () => import("../components/ConfirmDialog.vue"),
        uploader: () => import("../components/FilesUploader.vue"),
    },
    props: {
        dialog: Boolean,
        item: Object,
        user_list: Array,

        reparation_List: Array,
        failures: Array,
        barriers: Array,
        files_list: Array,
    },
    data() {
        return {
            repair_list: [],
            uploadedFiles: [],
            changes: 0,
            complete: 0,
            kfile: 100000,
            component: {
                id: null,
                agent_id: null,
                repair_date: null,
            },

            accept: true,
            dialog_accept: false,
            accept_comment: "",
            editedItem: {}, 
            cs: 1000, 
            status: 5,
            failure: {},
            uploading: false,

        };
    },
    watch: {},
    mounted() {
        if (this.dialog) {
            this.status = 5;
            this.changes = 0;
            this.complete = 0;
            this.editedItem = Object.assign({}, this.item);
            this.repair_list = Object.assign([], this.reparation_List);
            this.uploadedFiles = Object.assign([], this.files_list);
            console.log( this.uploadedFiles);
            if (this.editedItem.statut_id) this.status = this.editedItem.statut_id;
            this.kfile++;
            this.cs++;

        }
    },
    computed: {
        today() {
            return this.$store.state.today;
        },
        users_exe() {
            let l = [];
            l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
            return l;
        },
        users_eval() {
            let l = [];
            l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
            return l;
        },

    },
    methods: {
        change_files(files) {
            this.uploadedFiles = files;
            this.changes++;
        },

        closeDialog() {
            this.$emit("close_reparation");
        },

        async getFiles() {
            this.uploading = true;
            let r = await this.$apollo.query({
                query: getFiles_list,
                variables: {
                    id: this.failure.id,
                    table: "Rep"
                },
                fetchPolicy: "no-cache",
            });
            if (r) {
                this.uploadedFiles = r.data.get_files;

            }
            this.uploading = false;
            this.kfile++;

        },
        accept_dialog_close() {
            this.dialog_accept = false;
        },
        accept_daialog(value) {
            this.accept = value;
            this.dialog_accept = true;
        },
        recalc_failures() {
            let wel_failures = [];
            this.failures.forEach((x) => {
                if (
                    x.failure_id > 0 &&
                    x.statut_id < 2 &&
                    this.repair_list.findIndex(r => x.id == r.failure_id && !r.repared) >= 0
                )
                    wel_failures.push(x);
            });
            let failure = {
                cmp_code: "-2"
            };
            let multiples = this.$repaire_failure(wel_failures, this.barriers);
            // update multiples
            if (multiples.length > 0) {
                failure = multiples.reduce(function (prev, current) {
                    if (+current.failure_taux < +prev.failure_taux) {
                        return current;
                    } else {
                        return prev;
                    }
                });
            }
            //         if(failure.cmp_code!="-2")
            //         wel_failures.push(failure);
            //     var min = wel_failures.reduce(function (prev, current) {
            //     if (+current.due_date < +prev.due_date) {
            //         return current;
            //     } else {
            //         return prev;
            //     }
            // });
            return failure;
        },

        async save(status_id) { //
            if (this.$refs.form.validate()) {

                let fls = null;
                if (status_id == 7 && this.failure.repared) {

                    fls = await this.recalc_failures();
                }
                let rep = {
                    id: this.editedItem.id,
                    well_id: this.editedItem.well_id,
                    comment: this.editedItem.comment,
                    statut_id: status_id,
                    date_reparation: this.editedItem.date_reparation,
                    operator_id: this.editedItem.operator_id
                }
                let details = [];
                this.repair_list.forEach(x => {
                    if ((x.id && x.is != null) || x.id > 0 || x.repared) details.push({
                        id: x.id,
                        cmp_id: x.cmp_id,
                        well_tubular_id: x.well_tubular_id,
                        failure_id: x.failure_id,
                        //with_test: this.with_test,
                        repared: x.repared,
                    });
                });

                this.uploadedFiles.forEach(x => {
                    delete x["__typename"];
                    delete x["url"];
                });

                this.$apollo
                    .query({
                        query: SaveReparation,
                        variables: {
                            reparation: rep,
                            details: details,
                            multiple: fls,
                            files: this.uploadedFiles,
                            //comment: null,
                            user_id: parseInt(this.$store.state.me.id),
                        },
                        fetchPolicy: "no-cache",
                    })
                    .then((data) => {
                        this.editedItem.id = data.data.SaveRepair.id;
                        this.editedItem.status_id = status_id;
                        this.status = status_id;
                        this.uploadedFiles.forEach(x => x.content = null); 
                       console.log(this.editedItem)
                        this.kfile++;
                        this.dialog_accept = false;
                        this.$store.state.changed = true;
                        this.$emit("refresh_reparation");
                        this.changes = 0;
                        this.complete = 1;
                        if (this.status > 6) this.$emit("close_reparation");
                    })
                    .catch(() => {});
            }
        },
 

    },
};
</script>

<style scoped>
.scrollable-container {
    max-height: 600px;
    /* Hauteur maximum du conteneur */
    overflow-y: auto;
    /* Active le scroll vertical */
    padding: 10px;
    margin-top: 10px;
}

.rowh {
    margin-left: 12px;
    height: 25px !important;
}

.vrow {
    flex-wrap: wrap;
}
</style><style>
.rowh0 {
    height: 30px !important;
}

.rowh2 {
    height: 40px !important;
    margin-bottom: 8px !important;
}
</style>
